import autobind from 'autobind-decorator';
import React from 'react';
import Home from './Home';
import Portfolio,{CloseDiv} from './Portfolio';
import { Main } from './StyledComponents';
import {BrowserRouter as Router,Route,Link,Switch} from 'react-router-dom';
import AboutPage from './AboutPage';
import {PageHeader,PageFooter} from './PageComponents';
import { MenuOverlay,HeadLink,Menu } from './StyledComponents';




export interface LandState {
    scrolling:boolean;
    overlay:String;
}

class LandPage extends React.Component<{},LandState>{
    state={scrolling:false,overlay:''}
    
    @autobind
    handleScroll(event) {
        if(event.target.scrollTop>100){
            this.setState({scrolling:true})
        }
        else{
            this.setState({scrolling:false})
        }
    }

   



    render(){
        return(
            <React.Fragment>
                <Main id="mainDiv" onScroll={this.handleScroll}>
                <Router>
                    {/* <PageHeader click={()=>this.toggleOverlay()}/> */}
                    <Switch>
                        <Route path="/portfolio" component={Portfolio} />
                        <Route path="/about" component={AboutPage} />
                        <Route path="/" component={Home} />

                    </Switch>
                    
                </Router>
                </Main>
            </React.Fragment>
        )
    }

}

export default LandPage;
