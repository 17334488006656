import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Main = styled('div')({
    // display:'flex',
    // flexDirection:'column',
    position:'fixed',
    top:'0',
    left:'0',
    width:'100%', 
    height: '100%', 
    overflowX: 'none', 
    overflowY: 'auto'
})

export const Head = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    background: '#FFF',
    color:'#7C7C7C',
    padding:'8px 40px',
    zIndex:'999',
    position:'fixed',
    top:'0',
    left:'0',
    width: '100%',
    fontSize:'26px',
    transition:'all 0.2s ease',
    '#menuA':{display:'none'},
    '#menuB':{
        display:'flex',
        alignContent:'center',
        justifyContent:'center'
    },
    '@media screen and (max-width: 768px)':{
        backgroundColor: '#FFF',
        padding:'8px'
        
    },
    '&.solid':{
        background:'#FFF',
        padding:'8px 20px',
        fontSize:'26px',
    },
    '@media screen and (max-width: 520px)':{
        flexDirection: 'column',
        padding:'8px 16px',
        fontSize:'26px',
        '> h1':{
            padding:'5px'
        },
        background:'transparent',
        '#menuA':{display:'block'},
        '#menuB':{display:'none'}
    }

        
})

export const SpaceFill = styled('div')({
    display:'flex',
    flexGrow:'1'
})
export const HeadLink = styled(Link)({
    color:'#7C7C7C',
    fontSize:'20px',
    textDecoration:'none',
    margin:'10px',
    display: 'flex', 
    flexDirection: 'row', 
    alignItems: 'center',
    justifyContent:'center',
    textAlign:'center',
    fontFamily:'STIXGeneralRegular',
    letterSpacing:'0px',
    textTransform:'uppercase',
    '&:hover':{
        textDecoration:'none',
        color:'#7C7C7C',
        fontWeight:'900'
    },
    '@media screen and (max-width: 520px)':{
        fontSize:'15px',
        fontWeight:'700',
        margin:'0px 10px'
        
    } 
})

export const Menu = styled('nav')({
    alignItems:'center',
    justifyContent:'center',
    position:'relative',
    transform: 'translateX(100%)',
    transition: 'transform 0.5s ease',
    webkitOverflowScrolling: 'touch',
    boxShadow: 'none',
    padding:'40% 0%',
    'a':{
        color: '#fff',
        fontSize:'24px'
    },
    '> ul':{
        '> li':{
            lineHeight:'60px',
            
        }
    }, 

    '&.overlay': {
        transform: 'translateX(0%)'
    }
})

export const Footer = styled('div')({
    //backgroundColor:'#FFF',//#557A95
    justifyContent:'center',
    alignItems:'center',
    padding:'20px 20px',
    color:'#6C6C6C',
    fontFamily:'Arno Pro',
    ' a':{
        margin:'10px',
        color:'#050505',
        textDecoration:'none',
        fontFamily:'Arno Pro'
    },
    ' p':{
        fontFamily:'Arno Pro',
    }
})

export const FooterIcons = styled('div')({
    borderRadius:'25px',
    width:'50px',
    height:'50px',
    backgroundColor:'#3D3D3D',
    margin:'10px 20px',
    float:'none',
    display: 'flex', 
    flexDirection: 'row',
    alignItems: 'center', 
    justifyContent: 'center',
    '> a > img':{
        maxWidth: '21px', 
        maxHeight: '21px',
        //margin:'10px 0px 0px 10px',
    }
})



export const HeadHomeButton = styled(Link)({
    padding:'10px', 
    cursor: 'pointer', 
    color:'#7C7C7C', 
    fontSize: '26', 
    textDecoration: 'none'
})


export const ContentTemplate = styled('div')({
    display:'flex',
    flexDirection:'row',
    flexWrap:'wrap',
    justifyContent:'center',
    width:'100%',
    marginTop:'10px',
    marginBottom:'10px',
    borderRadius:'5px',
})

export const ItemTemplate = styled('div')({
    position: 'relative',
    width:'380px',
    margin:'15px',
    padding:'5px',
    textAlign:'start',
    cursor: 'pointer',
    textDecoration:'none',
    backgroundColor:'#FFF',
    ' h5':{
        padding:'5%',
        color:'#3D3D3D',
        fontFamily:'STIXGeneralRegular'
    },
    'a':{
        textDecoration:'none',
        '&:hover':{
            textDecoration:'none'
        }
    },
    '@media (max-width: 720px)': {
        width: '90%'
    }
})
export const ItemTemplatePress = styled('div')({
    position: 'relative',
    width:'380px',
    margin:'15px',
    padding:'5px',
    textAlign:'start',
    textDecoration:'none',
    backgroundColor:'#FFF',
    ' h5':{
        padding:'5%',
        color:'#3D3D3D',
        fontFamily:'STIXGeneralRegular'
    },
    'a':{
        textDecoration:'none',
        '&:hover':{
            textDecoration:'none',
            cursor: 'pointer',
        }
    },
    '@media (max-width: 720px)': {
        width: '90%'
    }
})

export const MenuOverlay = styled ('div')((props)=>{
    let ret: any ={
        display:'flex',
        height: '100%',
		width: '100%',
		position: 'fixed', /* Stay in place */
		zIndex: '1001', /* Sit on top */
		left: '0',
		top: '0',
		//backgroundColor: 'rgb(0,0,0)', /* Black fallback color */
		backgroundColor: 'rgba(200,60,65, 0.9)', /* Black w/opacity */
		overflowX: 'hidden', /* Disable horizontal scroll */
        transition: 'all 0.5s ease-in-out', /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
        justifyContent: 'center',
        alignItems: 'flex-start',
    }
    if(props.className !='overlay') {
        ret.backgroundColor = 'rgba(0,0,0, 0.0)'; /* Black w/opacity */
        ret.pointerEvents = 'none';
    }

    return ret;
})

export const Overlay=styled('div')((props)=>{
    let ret: any ={
        height: '100%',
		width: '100%',
		position: 'fixed', /* Stay in place */
		zIndex: '1001', /* Sit on top */
		left: '0',
		top: '0',
		//backgroundColor: 'rgb(0,0,0)', /* Black fallback color */
		backgroundColor: 'rgba(0,0,0, 0.8)', /* Black w/opacity */
		overflowX: 'hidden', /* Disable horizontal scroll */
        transition: 'all 0.5s ease-in-out', /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
        justifyContent: 'center',
        alignItems: 'center',
    }
    if(props.className !='overlay'){
        ret.backgroundColor = 'rgba(0,0,0, 0.0)'; /* Black w/opacity */
        ret.pointerEvents = 'none';
    }

    return ret;

    
})
export const OverlayIni=styled('div')((props)=>{
    let ret: any ={
        height: '100%',
		width: '100%',
		position: 'fixed', /* Stay in place */
        zIndex: '1001', /* Sit on top */
        left: '0',
        top: '0',
        display: 'flex', 
        flexDirection: 'row',
		backgroundColor: 'rgba(0,0,0, 1)', /* Black w/opacity */
		overflowX: 'hidden', /* Disable horizontal scroll */
        transition: 'all 1s ease-in-out', /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
        alignItems: 'center',
        justifyContent:'flex-end',
    }
    if(props.className !='overlay'){
        ret.backgroundColor = 'rgba(0,0,0, 0.0)'; /* Black w/opacity */
        ret.pointerEvents = 'none';
    }

    return ret;

    
})
export const OverlayContent = styled('div')({
    //display:'flex',
    //flexDirection:'column',
    alignItems:'flex-start',
    justifyContent:'flex-start',
    backgroundColor:'#FFF',
    // height:'70%',
    margin:'5% 10%',
    width:'80%',
    '@media screen and (max-width: 850px)':{
        //flexDirection:'column',
        width:'95%',
        margin:'2.5%',
        alignItems:'center',
    }
})
export const OverlayText = styled('div')({
    display:'flex',
    flexDirection:'column'
})
export const OverlayHeading = styled('h3')({
    display:'flex',
    margin:'40px 40px 20px 20px',
    color:'#000',
    fontSize:'24px',
    '@media screen and (max-width: 850px)':{
        margin:'20px',
    }
})
export const OverlayDesc = styled('p')({
    display:'flex',
    margin:'20px 40px 40px 20px',
    color:'#000',
    '@media screen and (max-width: 850px)':{
        margin:'20px',
    }
})

export const Section = styled('div')({
    display:'flex',
    flexDirection: 'column',
    alignItems:'center',
    justifyContent:'center',
    textAlign: 'center',
    width:'100%',
    backgroundColor:'#FFF',
    
})
export const ExecSection = styled('div')({
    alignItems:'center',
    justifyContent:'center',
    textAlign: 'center',
    width:'100%',
    backgroundColor:'#FFF',
    display: 'flex',
    flexDirection: 'column'
})

export const SectionHeader = styled('h2')({
    fontSize:'26px',
    margin:'0px 20px 20px 20px',
    color:'rgb(40,40,40)',
    textTransform: 'uppercase',
    fontFamily:'STIXGeneralRegular'
})



export const OverlayImage=styled('img')((props)=>{
    let ret ={
        height:'80%',
        margin:'40px',
        borderWidth:'5px',
        borderColor:'#FFF',
        '@media screen and (max-width: 850px)':{
            height:'300px',
            margin:'15px 15px 15px 15px',
        }
	  }
      if(props.className==='overlay'){
          return ret;
      }
      else
        return {};

})

export type ImageBackgroundProps = {
    url: string
}
export const ImageBackground = styled('div')<ImageBackgroundProps>(props => ({
    backgroundImage: `url(${props.url})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '100%', 
    paddingTop: '100%',
    height: '0px'
}))
export const ImageBackgroundPress = styled('div')<ImageBackgroundProps>(props => ({
    backgroundImage: `url(${props.url})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '100%', 
    paddingTop: '100%',
    height: '0px'
}))

export const Spinner = styled('div')({
    position:'fixed',
    left:'48%',
    top:'50%',
    width:'40px',
    height:'40px',
    border:'4px solid #8F8F91',
    borderTop:'4px solid white',
    borderRadius:'50%',
    transitionProperty: 'transform',
    animationName: 'rotation',
    animationDuration: '1.5s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    '@keyframes rotation' :{
        from :{transform: 'rotate(0deg)'},
        to :{transform: 'rotate(360deg)'}
    }
})
