import React from 'react';
import {Head,SpaceFill,HeadHomeButton,HeadLink,Footer,FooterIcons} from './StyledComponents';
import styled from 'styled-components';

export  const HamBurger = styled('a')((props)=>({
    textDecoration: 'none',
    color:props.menuColor,
    alignSelf:'center',
    paddingRight:'15px',    
    '&:hover':{
        textDecoration:'none',
        color:'#FFF',
    }
}))

export function PageHeaderHome(props){
    console.log(window.location.pathname)
    return(
        <Head >
            <div style={{display:'flex',flexDirection:'row'}}>
                <HeadHomeButton to="/" id="headLogo" disabled>
                    <img style={{width:'80px',opacity: 0}} alt="" src={require("./Images/logo.png")}></img>
                </HeadHomeButton>
                <SpaceFill/> 
                <HamBurger id="menuA" href="#menu" onClick={()=>props.click()} 
                        menuColor={(window.location.href.indexOf('/portfolio')>0 || 
                                    window.location.href.indexOf('/about')>0)?'#8F8F91':'#FFF'} >&#9776;</HamBurger>
            </div>
            <SpaceFill/> 
            <div id="menuB" >
                <HeadLink to="/" >Home</HeadLink>
                <HeadLink to="/portfolio">Portfolio</HeadLink>
                <HeadLink to="/about">About</HeadLink>
            </div>
        </Head>
    );
}

export function PageHeader(props){
    console.log(window.location.pathname)
    return(
        <Head >
            <div style={{display:'flex',flexDirection:'row'}}>
                
                <HeadHomeButton to="/" id="headLogo">
                    <img style={{width:'80px'}} alt="" src={require("./Images/logo.png")}></img>
                </HeadHomeButton>
                <SpaceFill/> 
                <HamBurger id="menuA" href="#menu" onClick={()=>props.click()} 
                        menuColor={(window.location.href.indexOf('/portfolio')>0 || 
                                    window.location.href.indexOf('/about')>0)?'#8F8F91':'#FFF'} >&#9776;</HamBurger>
            </div>
            <SpaceFill/> 
            <div id="menuB" >
                <HeadLink to="/" >Home</HeadLink>
                <HeadLink to="/portfolio">Portfolio</HeadLink>
                <HeadLink to="/about">About</HeadLink>
            </div>
        </Head>
    );
}

export function PageFooter(){
    return(
        <Footer>
            {/* <div style={{padding:'80px 0px 0px 0px',justifyContent:'center',display:'flex'}}>
                <FooterIcons><a href=""><img alt="" src={require("./Images/facebookLogo.svg")}></img></a></FooterIcons>
                <FooterIcons><a href=""><img alt="" src={require("./Images/instagram.svg")}></img></a></FooterIcons>
            </div> */}
            <div style={{padding:'50px 0px 5px 0px'}}>   
                <p style={{textAlign:'center'}}>
                    &copy; 2022 DHA Capital, LLC | All Rights Reserved { /*| <a href="https://www.apartmentsofny.com">Apartments of New York design</a> */}
                </p> 
            </div>
        </Footer>
    );
}