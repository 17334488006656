import * as React from 'react'
import styled from 'styled-components';
import autobind from 'autobind-decorator';
import {Overlay,OverlayContent, ExecSection,Section, SectionHeader, ContentTemplate, ItemTemplate, ImageBackground, OverlayIni, Spinner, MenuOverlay, Menu, HeadLink, ImageBackgroundPress, ItemTemplatePress } from './StyledComponents';
import {CloseDiv} from './Portfolio';
import getUrl from './config';
import { PageHeader } from './PageComponents';



const AboutPara = styled('p')({
    color:'#6C6C6C',
    fontSize: '16px',
    lineHeight: '24px', 
    width: '1100px',
    fontFamily:'Arno Pro',  
    textAlign: 'justify', 
    '@media (max-width: 1100px)': {
        width: '85%'
    }
})

export const ExecItem = styled('div')({
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    textAlign:'center',
    width:'280px',
    margin:'32px',
    padding:'0px',
    borderRadius:'3px'
})

export const ExecTeamContent = styled('div')({
    display:'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent:'center',
    alignItems:'center',
    width:'90%',
    margin:'10px',
    padding:'10px',
    borderRadius:'5px'
})


type ExecImageProps = {
    url: string
}
 
export const ExecImage = styled('div')<ExecImageProps>(props => ({
    paddingTop:'370px',
    width: '370px',
    height: '370px',
    backgroundImage: `url(${props.url})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top',
    '&:hover': {
        cursor: 'pointer'
    }
}))

export const ExecPara = styled('p')({
    color:'#6C6C6C',
    margin:'5px',
    font:'400 16px/24px "Arno Pro", sans-serif',
    fontFamily:'Arno Pro',  
    textAlign: 'justify',
})

export const ExecBold = styled('b')({
    color:'black',
    margin:'5px',
    marginTop: '24px',
    font:'700 16px/24px "STIXGeneralRegular", sans-serif',
    fontFamily:'STIXGeneralRegular'
})

export interface AboutState{
    overlay:String;
    menuOverlay: String;
    overlayItem:Object;
    error:String;
    isLoaded:Boolean;
    pressData:Array<Object>;
    execTeam:Array<Object>;
}


export default class AboutPage extends React.Component {
    state={overlay:'',menuOverlay: '', overlayItem:null, error: null, isLoaded:false, pressData:[],execTeam:[]}

    @autobind
    toggleOverlay(itemId){
        var item = this.state.execTeam.find(i=>i.id===itemId); 
        if(this.state.overlay===''){
            this.setState({
                overlay:'overlay',
                overlayItem:item
            })
            
        }
        else{
            this.setState({
                overlay:'',
                overlayItem:null
            })
        }
    }

    @autobind
    toggleOverlayMenu(){
        if(this.state.menuOverlay===''){
            this.setState({
                menuOverlay:'overlay',
            })
            
        }
        else{
            this.setState({
                menuOverlay:''
            })
        }
    }
    

    async componentDidMount(){
        
        let result = await fetch(getUrl("/pressnews")).then(res=>res.json(),(error)=>{
            this.setState({
                isLoaded:false,
                error
            });
        })
        let press=[];
        result?.map(item=>{
            let i = {
                id:item.acf.id,
                title:item.acf.title,
                link:item.acf.link,
                image:item.acf.image
            }
            press.push(i);
        })
        this.setState({
            pressData:press,
            isLoaded:true
        })
        console.log(this.state.pressData);
            

        fetch(getUrl("/team"))
        .then(res=>res.json())
        .then((result)=>{
            let team=[];
            result?.map(item=>{
                let i = {
                    id:item.acf.id,
                    name:item.acf.name,
                    image:item.acf.img,
                    desc:item.acf.description,
                    detailedDesc:item.acf.detaileddescription
                }
                team.push(i);
            })
            this.setState({
                execTeam:team,
                isLoaded:true
            })
            console.log(this.state.execTeam);
        },(error)=>{
            this.setState({
                isLoaded:false,
                error
            });
        })
    }

    render() {
        return (
            <React.Fragment>
               <PageHeader click={()=>this.toggleOverlayMenu()}/>
                <div style={{minHeight: '128px'}}/>

                <Section>
                    <SectionHeader>about us </SectionHeader>
                    <div style={{minHeight: '20px',width:'90%',borderTop:'1px solid #E0E0E0'}}></div>
                    <AboutPara> 
                        <span style={{color: 'red'}}>DHA Capital, LLC</span> is a real estate development 
                        and investment firm based in New York, NY and Austin, Texas. Founded in 2011, DHA focuses on the development 
                        of high-quality urban infill real estate projects in and around New York, NY and Austin, Texas.
                        DHA offers its partners and clients a long history of hands-on real estate experience
                        covering multiple asset types. The firm has a demonstrated track record of successful
                        projects and a unique ability to source off-market opportunities.
                        DHA’s deep development background allows the firm to execute complex, distinctive
                        projects that capture consumer demand and maximize investor value.
                    </AboutPara>
                </Section>  

                <div style={{minHeight: '84px'}}/>

                <ExecSection>
                    <SectionHeader>  </SectionHeader>
                    <div style={{minHeight: '20px',width:'90%',borderTop:'1px solid #E0E0E0'}}></div>
                    <ExecTeamContent>
                        {this.state.execTeam?.map(item=>(
                            <ExecItem key={item.id} onClick={()=>this.toggleOverlay(item.id)}>
                                <ExecImage url={item.image} />
                                <ExecBold>{item.name}</ExecBold>
                                <ExecPara>{item.desc}</ExecPara>
                            </ExecItem>
                        ))}
                    </ExecTeamContent>
                </ExecSection>

                <div style={{minHeight: '42px'}}/>
                <Section>
                    <SectionHeader >press releases</SectionHeader>
                    <div style={{minHeight: '20px',width:'90%',borderTop:'1px solid #E0E0E0'}}></div>
                    <ContentTemplate>
                        {this.state.pressData?.map(item=>(
                            <ItemTemplatePress key={item.id} >
                                <ImageBackgroundPress url={item.image} />
                                <a href={item.link} target="_blank"><h5>{item.title}</h5></a>
                            </ItemTemplatePress>
                        ))}
                    </ContentTemplate>
                </Section>
                <Overlay className={this.state.overlay}>
                    {this.state.overlay == 'overlay'?
                    <OverlayContent>
                        <CloseDiv className={this.state.overlay} onClick={()=>this.toggleOverlay(null)} ><img style={{height:'30px'}} alt="" src={require("./Images/cross.svg")}></img></CloseDiv>
                        <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',padding:'5%'}}>
                            <ExecImage url={this.state.overlayItem.image}/>
                            <ExecBold></ExecBold> {/*{this.state.overlayItem.name} */}
                            <ExecPara>{this.state.overlayItem.detailedDesc}</ExecPara>
                        </div>
                    </OverlayContent> : null}
                    
                </Overlay>
                <OverlayIni className={this.state.isLoaded?"":"overlay"}>
                    {!this.state.isLoaded?
                    <Spinner></Spinner>:null}
                    
                </OverlayIni>
                <MenuOverlay className={this.state.menuOverlay} onClick={()=>this.toggleOverlayMenu()}>
                    <div style={{paddingTop:'10px',width:'100%'}}>
                        <CloseDiv className={this.state.menuOverlay} onClick={()=>this.toggleOverlayMenu()} >
                            <img style={{height:'30px'}} alt="" src={require("./Images/crossWhite.svg")}></img>
                        </CloseDiv>
                        <Menu className={this.state.menuOverlay}>
                            <ul>
                                <li><HeadLink to="/" onClick={()=>this.toggleOverlayMenu()}>Home</HeadLink></li>
                                <li><HeadLink to="/portfolio" onClick={()=>this.toggleOverlayMenu()}>Portfolio</HeadLink></li>
                                <li><HeadLink to="/about" onClick={()=>this.toggleOverlayMenu()}>About</HeadLink></li>
                            </ul>
                        </Menu>
                    </div>
                </MenuOverlay>
            </React.Fragment>
        )
    }

}