

import * as React from 'react'
import * as ReactDOM from 'react-dom'
import './index.css';
import LandPage from './src/LandPage';


function bootstrap() {

    var content = document.querySelector('#app-content');
    ReactDOM.render(<LandPage/>, content);
}


document.addEventListener("DOMContentLoaded", bootstrap);