import React from 'react';
import styled from 'styled-components';
import autobind from 'autobind-decorator';
import {ContentTemplate,ItemTemplate,Overlay,OverlayContent,OverlayHeading,OverlayDesc,OverlayText,OverlayImage, Section, SectionHeader, ImageBackground, OverlayIni, Spinner, MenuOverlay, Menu, HeadLink} from './StyledComponents';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import getUrl from './config';
import { Link } from 'react-router-dom';
import { PageHeader } from './PageComponents';


export interface PortfolioState {
    overlay:String;
    menuOverlay: String;
    overlayItemID:String;
    overlayItem:Object;
    Projects:Array<Object>;
    isLoaded:Boolean;
    error:String;
}
export const ItemDiv = styled('div')({
    display:'flex',
    flexWrap: 'wrap',
    flexDirection:'row',
    width:'100%',
    padding:'2% 5% 5% 5%',
    backgroundColor:'#FFF',
    '@media screen and (max-width: 720px)': {
        flexDirection:'column',
    }
})
export const ImgDiv = styled('div')({
   
    width:'60%',
    alignItems:'center',
    '> img':{
        margin:'20px'
    },
    '@media screen and (max-width: 720px)': {
        width:'100%',
    }
})
export const ContentDiv = styled('div')({
    
    width:'40%',
    alignItems:'start',
    justifyContent:'flex-start',
    textAlign: 'flex-start',
    ' > h2':{
        fontSize:'38px',
        color:'#050505',
        fontFamily:'STIXGeneralRegular'
    },
   
    '@media screen and (max-width: 720px)': {
        width:'100%',
        margin:'20px 5px'
    }

})
export const DetailDiv = styled('div')({
    margin:'10px 10px 10px 0px',
    '> h5':{
        color:'#C55969',
        fontFamily:'STIXGeneralRegular'
    },
    '> p':{
        margin:'10px 20px 10px 0px',
        lineHeight:'18px',
        color:'#6C6C6C',
        fontFamily:'Arno Pro',
        textAlign:'justify'
    },
    '> a':{
        margin:'10px 20px 10px 0px',
        lineHeight:'20px',
        color:'#6C6C6C',
        fontFamily:'Arno Pro',
        textAlign:'justify'
    },
    '@media screen and (max-width: 720px)': {
        margin:'20px 5px 30px 5px',
        '> p':{
            margin:'20px 5px 10px 0px',
        },
        '> a':{
            margin:'20px 5px 10px 0px',
        }
    }
})

export const CloseDiv = styled('div')({
    
    padding:'3% 5% 0% 5%',
    '@media screen and (max-width: 720px)': {
        padding:'5% 5% 0% 5%',
    },

    display: 'none',

    '&.overlay': {
        display: 'block'
    }
})

class Portfolio extends React.Component<{},PortfolioState>{
    state={overlay:'',menuOverlay: '',overlayItemID:null,overlayItem:null,Projects:[],isLoaded:false,error:null}

    @autobind
    toggleOverlay(itemID){
        console.log(itemID);
        var item = this.state.Projects.find(i=>i.id===itemID); 
        if(this.state.overlay===''){
            this.setState({
                overlay:'overlay',
                overlayItemID:itemID,
                overlayItem:item
            })
            
        }
        else{
            this.setState({
                overlay:'',
                overlayItemID:null,
                overlayItem:null
            })
        }
    }

    @autobind
    toggleOverlayMenu(){
        if(this.state.menuOverlay===''){
            this.setState({
                menuOverlay:'overlay',
            })
            
        }
        else{
            this.setState({
                menuOverlay:''
            })
        }
    }
    

    componentDidMount(){
        fetch(getUrl("/projects"))
        .then(res=>res.json())
        .then((result)=>{
            let projList=[];
            console.log(result);
            result?.map(item=>{
                let i={
                    id:item.id,
                    name:item.acf.name,
                    details:[
                        {key:item.acf.add1, value:item.acf.address?.trim() != ""?item.acf.address.split(';'):[]},
                        {key:"Neighborhood", value:item.acf.neighborhood?.trim() != ""?item.acf.neighborhood.split(';'):[]},
                        {key:"No of units", value:item.acf.units?.trim() != ""?item.acf.units.split(';'):[]},
                        {key:"Architect",value:item.acf.architect?.trim() != ""?item.acf.architect.split(';'):[]},
                        {key:"Interior Designer",value:item.acf.interior_designer?.trim() != ""?item.acf.interior_designer?.split(';'):[]},
                        {key:"Amenities",value:item.acf.amenities?.trim() != ""?item.acf.amenities.split(';'):[]},
                        {key:"Description",value:item.acf.description?.trim() != ""?item.acf.description.split(';'):[]}
                    ],
                    images: Object.keys(item.acf).filter(k=>{return k.startsWith("img")})?.map((key)=>{return item.acf[key]})
                }
                projList.push(i);
            })
            console.log(projList)
            this.setState({
                Projects:projList,
                isLoaded:true
            })
            console.log(projList);
        },(error)=>{
            this.setState({
                isLoaded:false,
                error
            });
        })
    }

    render(){

        
        if(this.state.Projects == null) {
            return null;
        }

        return(
            <React.Fragment>
               <PageHeader click={()=>this.toggleOverlayMenu()}/>
                <Section>
                    <div style={{minHeight: '124px'}}/>
                    <SectionHeader >Portfolio</SectionHeader>
                    <div style={{minHeight: '32px',width:'90%',borderTop:'1px solid #E0E0E0'}}></div>
                    <ContentTemplate>
                        {this.state.Projects?.map(item=>(
                            <ItemTemplate key={item.id} onClick={()=>this.toggleOverlay(item.id)} >
                                <ImageBackground url={item.images[0]} />
                                <h5>{item.name}</h5>
                            </ItemTemplate>
                        ))}
                    </ContentTemplate>
                </Section>

                
                
                
                <Overlay className={this.state.overlay}>
                    {this.state.overlay == 'overlay'?
                    <OverlayContent>
                        <CloseDiv className={this.state.overlay} onClick={()=>this.toggleOverlay(null)} ><img style={{height:'30px'}} alt="" src={require("./Images/cross.svg")}></img></CloseDiv>
                        <ItemDiv>
                            <ContentDiv>
                                <h2>{this.state.overlayItem.name}</h2>    
                                {this.state.overlayItem.details?.map((i: any)=>(
                                    <DetailDiv key={i.key}>
                                        {i.value?.length>0?<h5>{i.key}</h5>:null}
                                        {i.key === "Amenities"?
                                        <p>{i.value?.join(' | ')}</p>
                                        :
                                        i.value?.map(pVal=>
                                            (pVal.indexOf("www.")<0)?
                                            <p key={pVal}>{pVal}</p>
                                            :
                                            <a key={pVal} href={`http://${pVal}`} target="blank">{pVal}</a>
                                        )
                                        }
                                        
                                    </DetailDiv>
                                ))}
                            </ContentDiv>
                            <ImgDiv>
                                <Carousel>
                                    {this.state.overlayItem.images?.map((img,index)=>(
                                        <Carousel.Item key={index}>
                                            <ImageBackground className="d-block w-100" url={img}></ImageBackground>
                                            {/* <img key={index} className="d-block w-100" style={{width:'90%'}} alt="" src={img}></img> */}
                                            
                                        </Carousel.Item>
                                        
                                    ))}
                                </Carousel>
                            </ImgDiv>
                        </ItemDiv>
                    </OverlayContent> : null}
                    
                </Overlay>
                <OverlayIni className={this.state.isLoaded?"":"overlay"}>
                    {!this.state.isLoaded?
                    <Spinner></Spinner>:null}
                    
                </OverlayIni>
                <MenuOverlay className={this.state.menuOverlay} onClick={()=>this.toggleOverlayMenu()}>
                    <div style={{paddingTop:'10px',width:'100%'}}>
                        <CloseDiv className={this.state.menuOverlay} onClick={()=>this.toggleOverlayMenu()} >
                            <img style={{height:'30px'}} alt="" src={require("./Images/crossWhite.svg")}></img>
                        </CloseDiv>
                        <Menu className={this.state.menuOverlay}>
                            <ul>
                                <li><HeadLink to="/" onClick={()=>this.toggleOverlayMenu()}>Home</HeadLink></li>
                                <li><HeadLink to="/portfolio" onClick={()=>this.toggleOverlayMenu()}>Portfolio</HeadLink></li>
                                <li><HeadLink to="/about" onClick={()=>this.toggleOverlayMenu()}>About</HeadLink></li>
                            </ul>
                        </Menu>
                    </div>
                </MenuOverlay>
            </React.Fragment>
        );
    }
}

export default Portfolio;